<template>
  <div class="container">
    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="article('search')">搜索</div>
      </template>
    </van-search>


    <van-empty :description="word" v-if="list.length == 0" />
    <div class="home-model" v-if="list.length > 0">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onRefresh"
      >
        <div class="news-list">
          <div v-for="(item,i) in list" :key="i" bindtap="goMedicineDetail" class="news-card"  @click="toBVue('/medicineDetail', item.id)">
            <div class="news-desc">
              <p class="news-title">{{item.title}}</p>
              <div class="news-span">
                <span>{{item.time}}</span>
                <span style="margin-left: 10px;">{{item.view_num}}浏览</span>
              </div>
            </div>
            <div class="list-img-box">
              <img :src="item.cover" alt="">
            </div>
          </div>
        </div>
      </van-list>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: '',
      list:[],
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        'offset':0,
        'limit': 10,
      }
    }
  },
  created() {
    this.article()
  },
  methods: {
    onRefresh() {
      this.article()
    },
    //医学智库
    article(type = '') {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      that.formData.keyword = that.keyword
      if (that.formData.offset == 0 || type == 'search') {
        that.list = []
      }
      that.refreshStatus = true
      this.$api.INDEX_ARTICLE_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.refreshStatus = false
          that.finished = true
        }
        if (res.length === 0) {
          that.refreshStatus = false
          that.word = '暂无数据'
          return false
        }
        res.forEach((item) => {
          that.list.push({
            id: item.id,
            title: item.title,
            view_num: item.view_num,
            cover: item.cover ? item.cover : '',
            time: item.create_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  }
}
</script>